import React, {useEffect, useState} from 'react';
import './App.scss';
import 'font-awesome/css/font-awesome.min.css';
import {
  FILTER_ALL,
  PATH_HOME,
  PATH_PROJECTS,
  HEADER_HEIGHT
} from "./constants";
import data from "./assets/data"; 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Carousel
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './scroll_to_top';

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <HeaderComponent />
        <div className="app-content">
          <Switch>
            <Route path={PATH_PROJECTS} component={ProjectComponent} />
            <Route path={PATH_HOME} component={HomeComponent} />
          </Switch>
        </div>
        <FooterComponent />
      </Router>
    </div>
  );
}

function HeaderComponent() {
  const [position, setPosition] = useState(0);
  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    function onScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition > (HEADER_HEIGHT / 2) && currentPosition > position) {
        setHidden(true);
      } else {
        setHidden(false);
      }
      setPosition(currentPosition);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [position]);

  return (
    <header className={`header-component ${isHidden ? "header--hidden" : ""}`}>
      <div className="header-component-inner">
        <div className="header-component-home">
          <Link to={PATH_HOME}>
            <h3>Leen Huynh</h3>
            <h5>Game Developer | Software Engineer</h5>
          </Link>
        </div>
        <div className="header-component-nav">
          <nav>
            <ul>
              <li>
                <a href="mailto:ljm6257@gmail.com" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-envelope" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/leen-massillon-huynh-9a63b98b/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-linkedin" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

class FooterComponent extends React.Component {
  render() {
    return (
      <footer className="footer-component">
        <p>© All rights reserved</p>
      </footer>
    );
  }
}

class HomeComponent extends React.Component {
  get projects() {
    return data.works;
  }

  get filters() {
    return [FILTER_ALL, ...new Set(
      Object.keys(this.projects).map((key) => this.projects[key].enabled ? this.projects[key].languages.split(",") : null).flat().sort())
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      filter: this.filters[0]
    }
  }

  setActiveFilter(filter) {
    this.setState({
      filter
    });
  }

  render() {
    return (
      <div className="home-component">
        <Container>
          <section>
            <div className="home-component-section-title"><div><h4>WHO I AM</h4></div></div>
            <Row>
              <Col sm lg={4}>
                <div>
                  <img 
                    src={require(`./assets/images/avatar2.jpg`)}
                    alt="avatar"
                  />
                </div>
              </Col>
              <Col sm lg={8}>
                <div>
                  <h5>Hello!</h5>
                  <p>
                    My name is Leen (pronouced "lean" like lean mean coding machine). I enjoy creating mobile and web applications focused on delivering great experiences. I am a software engineer currently working on Wayfair's iOS app. With over eight years of professional experience and a background in Game Design &amp; Development, I've been able to fine tune my skills on many interesting projects. Check them out below!
                  </p>
                  <p>
                    When I'm not busy programming, you'll probably find me dancing, reading, playing games, cuddling with my dog, or spending time with my family and friends.
                  </p>
                  <Row className="home-component-about-buttons justify-content-start">
                    <Col md="auto">
                      <a 
                        href="mailto:ljm6257@gmail.com" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="button"
                      >
                        CONTACT ME
                      </a>
                    </Col>
                    <Col md="auto">
                      <a 
                        href={`${process.env.PUBLIC_URL}/leen_huynh_resume.pdf`}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="button"
                      >
                        DOWNLOAD RESUME
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
          <section>
            <div className="home-component-section-title"><div><h4>WHAT I DO</h4></div></div>
            <Row className="home-component-portfolio-filter justify-content-center">
              {this.filters.map((filter) => {
                return (
                  <Col 
                    md="auto"
                    key={filter} 
                    data-filter={filter} 
                    className={`w-auto ${filter === this.state.filter ? "active" : ""}`}
                    onClick={() => this.setActiveFilter(filter)}
                  >
                    {filter}
                  </Col>
                );
              })}
            </Row>
            <div className="home-component-portfolio-content">
              {Object.keys(this.projects).map((key) => 
              {
                var project = this.projects[key];
                if ((this.state.filter !== FILTER_ALL && !project.languages.includes(this.state.filter)) || !project.enabled) {
                  return null;
                }

                return (
                  <Link 
                    to={`${PATH_PROJECTS}/${key}`}
                    key={key}
                  >
                    <ProjectCard {...project} />
                  </Link>
                );
              })}
            </div>
          </section>
        </Container>
      </div>
    );
  }
}

class ProjectCard extends React.Component {
  render() {
    const {
      title,
      subtitle,
      preview
    } = this.props;

    var divImage = require(`./assets/images/${preview}`);
    var divStyle = {
      backgroundImage: `url(${divImage})`
    }

    return (
      <div className="project-card">
        <div className="project-card-image" style={divStyle}>
          <div className="project-card-content">
            <h5>{title.toUpperCase()}</h5>
            <p><b>{subtitle}</b></p>
          </div>
        </div>
      </div>
    );
  }
}

class ProjectComponent extends React.Component {
  constructor(props) {
    super(props);

    var project = this.findProject();
    this.state = {
      project,
      redirect: project === null || project === undefined
    };
  }

  get id() {
    var path = this.props.location.pathname;
    return path.split("/").pop();
  }

  findProject() {    
    var project = data.works[this.id];
    return project;
  }

  previousProject() {
    var keys = Object.keys(data.works);
    var index = keys.indexOf(this.id);
    var nextIndex = index + 1;
    if (nextIndex >= keys.length) {
      nextIndex = 0;
    }

    this.setProject(keys[nextIndex]);
  }

  nextProject() {
    var keys = Object.keys(data.works);
    var index = keys.indexOf(this.id);
    var nextIndex = index - 1;
    if (nextIndex < 0) {
      nextIndex = keys.length - 1;
    }
    this.setProject(keys[nextIndex]);
  }

  setProject(projectId) {
    this.props.history.push(`${PATH_PROJECTS}/${projectId}`);
    this.setState({
      project: data.works[projectId]
    });
  }

  formatSubtitle(subtitle) {
    if (typeof subtitle === 'string') {
      return subtitle;
    }

    return (
      <a href={subtitle.link} target="_blank" rel="noopener noreferrer">{subtitle.text}</a>
    );
  }

  render() {
    if (this.state.redirect) return <Redirect to={PATH_HOME} />

    const {
      title,
      description,
      company,
      date,
      platform,
      languages,
      images,
      responsibilities,
      outcomes,
      links,
      awards
    } = this.state.project;
    const details = [
      {title: "Responsibilities", subtitle: responsibilities},
      {title: "Outcomes", subtitle: outcomes},
      {title: "Company", subtitle: company},
      {title: "Date", subtitle: date},
      {title: "Platforms", subtitle: platform},
      {title: "Languages", subtitle: languages},
      {title: "Awards", subtitle: awards},
      {title: "Links", subtitle: links}
    ];
    return(
      <Container className="project-component">
        <h3>{title}</h3>
        <Row>
          <Col md={12} lg={6}>
            <div>
              <p dangerouslySetInnerHTML={{__html: description}} />
              {details.map((detail) => {
                if (detail.subtitle === undefined) { return null; }
                
                return (
                  <div className="project-section" key={detail.title}>
                    <b>{detail.title}</b><br/>
                    <div>{Array.isArray(detail.subtitle) 
                      ? <ul>{detail.subtitle.map((item, index) => <li key={index}>{this.formatSubtitle(item)}</li>)}</ul>
                      : this.formatSubtitle(detail.subtitle)
                    }</div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={{order: 'first'}} md={{size: 12, order: 'first'}} lg={{size: 6, order: 'last'}}>
            <Carousel
              indicators={false}
            >
              {images.map((image) =>
                <Carousel.Item key={image}>
                  <img
                    className="d-block w-100" 
                    src={require(`./assets/images/${image}`)} 
                    alt={image}
                  />
                </Carousel.Item>
              )}
            </Carousel>
          </Col>
        </Row>
        <div className="project-navigation">
          <Row className="justify-content-between">
            <Col xs="auto"><button onClick={() => this.previousProject()}>&lsaquo; Previous Project</button></Col>
            <Col xs="auto" className="text-center"><Link to={PATH_HOME}><i className="fa fa-th-large" /></Link></Col>
            <Col xs="auto"><button onClick={() => this.nextProject()}>Next Project &rsaquo;</button></Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default App;
